/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ApiCmdStatus {
    PENDING = 'PENDING',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETE = 'COMPLETE',
    FAILED = 'FAILED',
}

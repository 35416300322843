/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum HostState {
    OFFLINE = 'OFFLINE',
    ONLINE = 'ONLINE',
    JOIN_PENDING = 'JOIN_PENDING',
    ARCHIVED = 'ARCHIVED',
}

/* SSHKEYS */
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_SSHKEYS = "GET_SSHKEYS"

/**
* Add KEY
*/
export const ADD_SSH_KEY = "ADD_SSH_KEY";
export const ADD_SSH_KEY_SUCCESS = "ADD_SSH_KEY_SUCCESS";
export const ADD_SSH_KEY_FAIL = "ADD_SSH_KEY_FAIL";

/**
* Import KEY
*/
export const IMPORT_SSH_KEYS = "IMPORT_SSH_KEYS";
export const IMPORT_SSH_KEYS_SUCCESS = "IMPORT_SSH_KEYS_SUCCESS";
export const IMPORT_SSH_KEYS_FAIL = "IMPORT_SSH_KEYS_FAIL";


/**
 * Delete KEY
 */
export const DELETE_SSH_KEY = "DELETE_SSH_KEY";
export const DELETE_SSH_KEY_SUCCESS = "DELETE_SSH_KEY_SUCCESS";
export const DELETE_SSH_KEY_FAIL = "DELETE_SSH_KEY_FAIL";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum StorageDeviceType {
    SCSI = 'SCSI',
    NVME = 'NVMe',
    LOOPBACK = 'Loopback',
    OTHER = 'Other',
    VIRT_IO = 'VirtIo',
}

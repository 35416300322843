import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Crypto Redux States
import { 
    GET_SSHKEYS,
    ADD_SSH_KEY,
    DELETE_SSH_KEY,
    IMPORT_SSH_KEYS,
} from "./actionTypes"
import {
    KeyApiResponseSuccess,
    KeyApiResponseError,
    addSSHKeySuccess,
    addSSHKeyFail,
    deleteSSHKeySuccess,
    deleteSSHKeyFail,
    importSSHKeysSuccess,
    importSSHKeysFail,
} from "./actions"

//Include Both Helper File with needed methods
import { 
    getSSHKeys,
    addSSHKey,
    deleteSSHKey,
    importSSHKeys
} from "helpers/galliumbackend_helper"

function* getKeyList() {
    try {
        const response = yield call(getSSHKeys)
        yield put(KeyApiResponseSuccess(GET_SSHKEYS, response))
    } catch (error) {
        yield put(KeyApiResponseError(GET_SSHKEYS, error))
    }
}

function* onAddNewKey({ payload: sshkey }) {
    try {
        const response = yield call(addSSHKey, sshkey);
        yield put(addSSHKeySuccess(response));
        toast.success("SSH Key Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(addSSHKeyFail(error));
        toast.error("SSH Key Addition Failed", { autoClose: 3000 });
    }
}

function* onImportSSHKeys({ payload: gh_username }) {
    try {
        const response = yield call(importSSHKeys, gh_username);
        yield put(importSSHKeysSuccess(response));
        toast.success("SSH Keys Imported Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(importSSHKeysFail(error));
        toast.error("SSH Key Import Failed", { autoClose: 3000 });
    }
}

function* onDeleteSSHKey({ payload: sshkey }) {
    try {
        const response = yield call(deleteSSHKey, sshkey);
        yield put(deleteSSHKeySuccess({ sshkey, ...response }));
        toast.success("SSH Key Deleted Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(deleteSSHKeyFail(error));
        toast.error("SSH Key Deletion Failed", { autoClose: 3000 });
    }
}

export function* watchGetKeyList() {
    yield takeEvery(GET_SSHKEYS, getKeyList);
}

export function* watchAddNewKey() {
    yield takeEvery(ADD_SSH_KEY, onAddNewKey);
}

export function* watchImportSSHKeys() {
    yield takeEvery(IMPORT_SSH_KEYS, onImportSSHKeys);
}

export function* watchDeleteSSHKey() {
    yield takeEvery(DELETE_SSH_KEY, onDeleteSSHKey);
}

function* SSHKeysSaga() {
    yield all([
        fork(watchGetKeyList),
        fork(watchAddNewKey),
        fork(watchImportSSHKeys),
        fork(watchDeleteSSHKey)
    ]
    );
}

export default SSHKeysSaga

import { TemplateDisk } from "generated";
import {
    GET_TEMPLATES_REQUEST,
    GET_TEMPLATES_SUCCESS,
    GET_TEMPLATES_FAIL,
    GET_TEMPLATE_DETAIL_REQUEST,
    GET_TEMPLATE_DETAIL_SUCCESS,
    GET_TEMPLATE_DETAIL_FAIL,
    GET_TEMPLATE_DISK_REQUEST,
    GET_TEMPLATE_DISK_SUCCESS,
    GET_TEMPLATE_DISK_FAIL,
    CREATE_TEMPLATE_REQUEST,
    CREATE_TEMPLATE_SUCCESS,
    CREATE_TEMPLATE_FAIL,
    CREATE_TEMPLATE_DISK_REQUEST,
    CREATE_TEMPLATE_DISK_SUCCESS,
    CREATE_TEMPLATE_DISK_FAIL,
    DELETE_TEMPLATE_DISK_REQUEST,
    DELETE_TEMPLATE_DISK_SUCCESS,
    DELETE_TEMPLATE_DISK_FAIL,
    UPDATE_TEMPLATE_REQUEST,
    UPDATE_TEMPLATE_SUCCESS,
    UPDATE_TEMPLATE_FAIL,
    DELETE_TEMPLATE_REQUEST,
    DELETE_TEMPLATE_SUCCESS,
    DELETE_TEMPLATE_FAIL,
    RESET_TEMPLATE_FLAGS
} from "./actionTypes"

export const getTemplates = data => ({
    type: GET_TEMPLATES_REQUEST,
    data
})

export const getTemplatesSuccess = (data) => ({
    type: GET_TEMPLATES_SUCCESS,
    payload: { data },
});

export const getTemplatesFail = (error) => ({
    type: GET_TEMPLATES_FAIL,
    payload: { error },
});

export const getTemplateDetail = (templateSlug) => ({
    type: GET_TEMPLATE_DETAIL_REQUEST,
    templateSlug
})

export const getTemplateDetailSuccess = data => ({
    type: GET_TEMPLATE_DETAIL_SUCCESS,
    payload: data,
});

export const getTemplateDetailFail = (error) => ({
    type: GET_TEMPLATE_DETAIL_FAIL,
    payload: { error },
});

export const getTemplateDisk = (templateSlug, diskSlug) => ({
    type: GET_TEMPLATE_DISK_REQUEST,
    templateSlug,
    diskSlug
})

export const getTemplateDiskSuccess = data => ({
    type: GET_TEMPLATE_DISK_SUCCESS,
    payload: data,
});

export const getTemplateDiskFail = (error) => ({
    type: GET_TEMPLATE_DISK_FAIL,
    payload: { error },
});
  
export const createTemplate = (data, history) => ({
    type: CREATE_TEMPLATE_REQUEST,
    data,
    history
});
  
export const createTemplateSuccess = (data) => ({
    type: CREATE_TEMPLATE_SUCCESS,
    payload: { data },
});
  
export const createTemplateFail = (error) => ({
    type: CREATE_TEMPLATE_FAIL,
    payload: { error }
});

export const createTemplateDisk = (templateSlug, data) => ({
    type: CREATE_TEMPLATE_DISK_REQUEST,
    templateSlug,
    data
});
  
export const createTemplateDiskSuccess = data => ({
    type: CREATE_TEMPLATE_DISK_SUCCESS,
    payload: data,
});
  
export const createTemplateDiskFail = (error) => ({
    type: CREATE_TEMPLATE_DISK_FAIL,
    payload: { error }
});

export const deleteTemplateDisk = (disk: TemplateDisk) => ({
    type: DELETE_TEMPLATE_DISK_REQUEST,
    disk
});
  
export const deleteTemplateDiskSuccess = (response: object, disk: TemplateDisk) => ({
    type: DELETE_TEMPLATE_DISK_SUCCESS,
    response,
    disk
});
  
export const deleteTemplateDiskFail = (error) => ({
    type: DELETE_TEMPLATE_DISK_FAIL,
    payload: { error }
});

export const updateTemplate = (templateSlug, data, history) => ({
    type: UPDATE_TEMPLATE_REQUEST,
    templateSlug,
    data,
    history
});
  
export const updateTemplateSuccess = data => ({
    type: UPDATE_TEMPLATE_SUCCESS,
    payload: data,
});
  
export const updateTemplateFail = (error) => ({
    type: UPDATE_TEMPLATE_FAIL,
    payload: { error }
});

export const deleteTemplate = (templateSlug, history) => ({
    type: DELETE_TEMPLATE_REQUEST,
    templateSlug,
    history
});
  
export const deleteTemplateSuccess = data => ({
    type: DELETE_TEMPLATE_SUCCESS,
    payload: data,
});
  
export const deleteTemplateFail = (error) => ({
    type: DELETE_TEMPLATE_FAIL,
    payload: { error }
});

export const resetTemplateFlags = () => {
    return {
        type: RESET_TEMPLATE_FLAGS,
    }
}

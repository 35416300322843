import { MarketingPersonalisation, MarketingService, ServiceGateway, ServiceGatewayCreateRequest, ServiceGatewayDeleteResponse, ServiceGatewayService } from "generated";
import useSWR from "swr";
import useSWRMutation from 'swr/mutation'

export const useListPersonalisation = (key: string) => {
    const listPersonalisation = () => MarketingService.listPersonalisation(key)
    
    const { data, error, isLoading } = useSWR(`marketing`, listPersonalisation);

    return { data, error, isLoading };
};

export const useSetPersonlisation = (key: string) => {
    const authKey = key
    const setPersonlisation = (key: null, {arg}:{arg:MarketingPersonalisation}) => MarketingService.setPersonlisation(authKey, arg)
    
    const { trigger, error, isMutating } = useSWRMutation<string>(`sg`, setPersonlisation);

    return { trigger, error, isMutating };
};


export const useDeleteServiceGateway = () => {
    const deleteServiceGateway = (key: null, {arg}:{arg:ServiceGateway}) => ServiceGatewayService.deleteServiceGateway(arg.slug)
    
    const { trigger, error, isMutating } = useSWRMutation<ServiceGatewayDeleteResponse>(`sg`, deleteServiceGateway);

    return { trigger, error, isMutating };
};


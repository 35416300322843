import React from 'react';
const FallbackComponent = () => {
    document.title="Application Error";
    window.Intercom('trackEvent', 'experienced-error');
    return (
        <React.Fragment>
            <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="auth-page-content overflow-hidden p-0">
                    <div className="row justify-content-center">
                        <div className="col-xl-4 text-center">
                            <div className="error-500 position-relative">
                                <h1 className="title text-primary">!</h1>
                            </div>
                            <div>
                                <h4>Application Error!</h4>
                                <p className="text-muted w-75 mx-auto">Something has gone wrong</p>
                                <a href="/hypervisors" className="btn btn-primary"><i className="mdi mdi-home me-1"></i>Back to home</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export default FallbackComponent;
import Login from "pages/Authentication/Login";
import AddPersonalisation from "pages/Personalisations/Add";
import PersonalisationsList from "pages/Personalisations/List";
import React from "react";
import { Navigate } from "react-router-dom";


const authProtectedRoutes = [

    { path: "/landingpage/add", component: <AddPersonalisation /> },
    { path: "/landingpage", component: <PersonalisationsList /> },

    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    {
        path: "/",
        exact: true,
        component: <Navigate to="/hypervisors" />,
    },
    { path: "*", component: <Navigate to="/login" /> },
]

const publicRoutes = [
    

    { path: "/login", component: <Login /> },
]

export { authProtectedRoutes, publicRoutes }

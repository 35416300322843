//LOGIN
export const POST_JWT_LOGIN = "/api/login"
export const POST_JWT_REGISTER = "/api/register"

//HYPERVISORS
export const GET_HYPERVISORS = "/api/hosts"
export const GET_HYPERVISOR_DETAIL_REQUEST = "/api/hosts"
export const GET_HYPERVISOR_METRICS = "/api/hosts"
export const GET_HYPERVISOR_HARDWARE = "/api/hosts"
export const UPDATE_HYPERVISOR = "/api/hosts"
export const ADD_HYPERVISOR = "/api/hosts/join"
export const GET_HYPERVISOR_STORAGE = "/api/hosts"
export const UPDATE_HYPERVISOR_STORAGE = "/api/hosts"
export const DESTROY_HYPERVISOR_STORAGE = "/api/hosts"



//VNC Viewer
export const GET_VNC_URL = "/api/vm"

// SSH Keys
export const GET_SSHKEYS = "/api/sshkeys"
export const ADD_SSHKEY = "/api/sshkeys"
export const IMPORT_SSHKEYS = "/api/sshkeys/import"
export const DELETE_SSHKEY = "/api/sshkeys"


export const ADD_VIRTUAL_MACHINE  = "/api/hosts"

export const GET_VIRTUAL_MACHINES  = "/api/vm"
export const GET_VIRTUAL_MACHINE_DETAIL  = "/api/vm"

export const CHANGE_VIRTUAL_MACHINE_STATE  = "/api/vm"


export const GET_TEMPLATES  = "/api/templates"
export const GET_TEMPLATE_DETAIL  = "/api/templates"
export const GET_TEMPLATE_DISK  = "/api/templates"
export const CREATE_TEMPLATE  = "/api/templates/newtemplate"
export const CREATE_TEMPLATE_DISK = "/api/templates"
export const DELETE_TEMPLATE_DISK = "/api/templates"
export const UPDATE_TEMPLATE  = "/api/templates"
export const DELETE_TEMPLATE  = "/api/templates"


// Commands
export const GET_COMMANDS  = "/api/cmd"



import React from 'react';

import {
    Spinner,

} from "reactstrap"

const Loader = () => {
    return (
        <React.Fragment>
            <div className='text-center'>
                <Spinner color="info"> Loading...</Spinner>
            </div>
        </React.Fragment>
    )
};

export default Loader;
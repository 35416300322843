import { getIn } from 'formik';
import React from 'react';
import { FormFeedback, Input, Label, Button, Tooltip } from "reactstrap";

import FeatherIcon from 'feather-icons-react';


const parseErrors = (error, parentKey = 'root') => {
    if (error === null) return [];

    if (typeof error === 'string') 
        return [<p className="mb-1" key={parentKey}>{error}</p>];

    if (Array.isArray(error))
        return error.flatMap((err, i) => parseErrors(err, `${parentKey}-${i}`));

    if (typeof error === 'object') 
        return Object.entries(error).flatMap(([key, err]) => parseErrors(err, `${parentKey}-${key}`));
    
    return [];
};

const GalliumInput = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => {

    return (
        <div>
            {props.label ? (<Label htmlFor={field.id}>{props.label}</Label>):(null)}
            <Input
                {...field}
                {...props}
                invalid={
                    touched[field.name] && errors[field.name] ? true : false
                }
            />

            {touched[field.name] &&
            errors[field.name] && <FormFeedback type="invalid">{errors[field.name]}</FormFeedback>}
        </div>
    )

}

const GalliumArrayInput = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => {

    const error = getIn(errors, field.name);
    const touch = getIn(touched, field.name);

    return (
        <div>
            {props.label ? (<Label htmlFor={field.id}>{props.label}</Label>):(null)}
            <Input
                {...field}
                {...props}
                invalid={
                    touch && error ? true : false
                }
            />
        </div>
    )

}


const GalliumSpinnerButton = ({ color }) => {
    return(
        <Button
            color={color}
            className="btn-load"
        >
            <span className="d-flex align-items-center">
                <span className="flex-grow-1 me-2">
                    Working
                </span>
                <span className="spinner-grow flex-shrink-0" role="status">
                    <span className="visually-hidden">Working</span>
                </span>
            </span>
        </Button>
    )
}

const GalliumSubmitButton = ({ formik, children, color, spinner, ...props }) => {

    const [isBtntooltipOpen, setBtnTooltipOpen] = React.useState(false);

    if (spinner){
        return(
            <Button
                color={color}
                className={"btn-load " + props.className}
            >
                <span className="d-flex align-items-center">
                    <span className="flex-grow-1 me-2">
                        Working
                    </span>
                    <span className="spinner-grow flex-shrink-0" role="status">
                        <span className="visually-hidden">Working</span>
                    </span>
                </span>
            
            </Button>

        )
    } else if (formik.errors && Object.keys(formik.errors).length > 0) {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    id="GalBtn"
                    color="secondary"
                    {...props}
                >
                    
                    {children}
                </Button>

                <Tooltip
                    isOpen={isBtntooltipOpen}
                    placement="left"
                    target="GalBtn"
                    toggle={() => { setBtnTooltipOpen(!isBtntooltipOpen) }}>
                    {
                        Object.entries(formik.errors).flatMap(([field, errorMsg], index) => parseErrors(errorMsg).map((ErrorComponent, i) => <div key={`${index}-${i}`}>{ErrorComponent}</div>))
                    }
                </Tooltip>
            </React.Fragment>
        )
    } else {
        return(
            <Button
                color={color}
                type="button"
                {...props}
                onClick={() => {
                    formik.handleSubmit()
                }}
            >
                {children}
            </Button>
        )
    }
}

const GalliumFormHeader = ({title, icon, children}) => {

    return (
        <React.Fragment>
            <div className="d-flex mb-0">
                <div className="flex-shrink-0 me-3">
                    <FeatherIcon icon={icon} className={"text-info icon-dual-info icon-xs"} />
                </div>
                <div className="flex-grow-1">
                    <h5>{title}</h5>
                    {/* <Label className="form-label">Create mappings to direct traffic to Virtual Machines</Label> */}
                    {children}
                </div>
            </div>
        </React.Fragment>
    )
};

export { GalliumInput, GalliumArrayInput, GalliumSubmitButton, GalliumSpinnerButton, GalliumFormHeader };
import {
    GET_SSHKEYS,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    ADD_SSH_KEY_SUCCESS,
    ADD_SSH_KEY_FAIL,
    IMPORT_SSH_KEYS_SUCCESS,
    IMPORT_SSH_KEYS_FAIL,
    DELETE_SSH_KEY_SUCCESS,
    DELETE_SSH_KEY_FAIL,
} from "./actionTypes"
  
const INIT_STATE = {
    list: [],
}
  
const SSHKeys = (state = INIT_STATE, action) => {
    switch (action.type) {
    case API_RESPONSE_SUCCESS:
        switch (action.payload.actionType) {

        case GET_SSHKEYS:
            return {
                ...state,
                list: action.payload.data.keys,
                isKeyCreated: false,
                isKeySuccess: true
            };

        default:
            return { ...state };
        }

    case API_RESPONSE_ERROR:
        switch (action.payload.actionType) {
        case GET_SSHKEYS:
            return {
                ...state,
                error: action.payload.error,
                isKeyCreated: false,
                isKeySuccess: false
            };

        default:
            return { ...state };
        }

    case GET_SSHKEYS: {
        return {
            ...state,
            isKeyCreated: false,
        };
    }

    case ADD_SSH_KEY_SUCCESS:
        return {
            ...state,
            isKeyCreated: true,
            list: [...state.list, action.payload],
            isKeyAdd: true,
            isKeyAddFail: false,
        };

    case ADD_SSH_KEY_FAIL:
        return {
            ...state,
            error: action.payload,
            isKeyAdd: false,
            isKeyAddFail: true,
        };

    case IMPORT_SSH_KEYS_SUCCESS:
        return {
            ...state,
            isKeyAdd: true,
            isKeyAddFail: false,
        };
  
    case IMPORT_SSH_KEYS_FAIL:
        return {
            ...state,
            error: action.payload,
            isKeyAdd: false,
            isKeyAddFail: true,
        };
    

    case DELETE_SSH_KEY_SUCCESS:
        return {
            ...state,
            list: state.list.filter(
                sshkey => sshkey.slug.toString() !== action.payload.sshkey.slug.toString()
            ),
            isKeyDelete: true,
            isKeyDeleteFail: false
        };

    case DELETE_SSH_KEY_FAIL:
        return {
            ...state,
            error: action.payload,
            isKeyDelete: false,
            isKeyDeleteFail: true
        };

    default:
        return state
    }
}
  
export default SSHKeys
  
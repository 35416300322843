import React, { useEffect, useState } from 'react';

import BreadCrumb from '../../../Components/Common/Breadcrumb';

import {

    Button,
    Container, Input,
} from "reactstrap"
import { Field, Form, FormikProvider, useFormik } from 'formik';
import { GalliumInput } from 'Components/Gallium/GalliumForms';
import PersonalisationsListTable from './TableComponent';



const PersonalisationsList = () => {
    const [key, setKey] = useState(null)

    useEffect(() => {
        if (localStorage.getItem("marketingKey")) {
            setKey(localStorage.getItem("marketingKey"))
        }
    }, []);
    
    const loginFormik = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            key: '',
        },
        onSubmit: (values) => {
            localStorage.setItem("marketingKey", values["key"])
            setKey(values["key"]);
        }
    });

    document.title = "Marketing Personalisations | Gallium";
    return (
        <React.Fragment>
            <div className="page-content">
                
                <Container fluid>
                    <BreadCrumb title="Personalisations" pageTitle="Marketing Toolkit" />
                    {key ? (
                        <PersonalisationsListTable passkey={key} />
                    ):(
                        <FormikProvider value={loginFormik}>
                            <Field
                                label="Key"
                                name="key"
                                id="key"
                                className="mb-3"
                                placeholder="Enter key"
                                type="text"
                                component={GalliumInput}
                            />
                            <Button type="submit" color="success" onClick={() => loginFormik.handleSubmit()}>Go</Button>
                        </FormikProvider>
                    )}            
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PersonalisationsList;
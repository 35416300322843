import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { 
    GET_VNC_URL
} from "./actionTypes"
import {
    getVNCUrlSuccess,
    getVNCUrlFail,
} from "./actions"

//Include Both Helper File with needed methods
import { 
    getVNCUrl,
} from "helpers/galliumbackend_helper"

function* fetchVNCUrl({ virtualMachineSlug }) {
    try {
        const response = yield call(getVNCUrl, virtualMachineSlug)
        yield put(getVNCUrlSuccess(response))
    } catch (error) {
        yield put(getVNCUrlFail(error))
    }
}

function* VmViewerSaga() {
    yield takeEvery(GET_VNC_URL, fetchVNCUrl)
}

export default VmViewerSaga
import {
    GET_HYPERVISORS,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    GET_HYPERVISOR_DETAIL_FAIL,
    GET_HYPERVISOR_DETAIL_SUCCESS,
    GET_HYPERVISOR_METRICS_FAIL,
    GET_HYPERVISOR_METRICS_SUCCESS,
    GET_HYPERVISOR_STORAGE_FAIL,
    GET_HYPERVISOR_STORAGE_SUCCESS,
    UPDATE_HYPERVISOR_STORAGE_FAIL,
    UPDATE_HYPERVISOR_STORAGE_SUCCESS,
    DESTROY_HYPERVISOR_STORAGE_FAIL,
    DESTROY_HYPERVISOR_STORAGE_SUCCESS,
    UPDATE_HYPERVISOR_SUCCESS,
    UPDATE_HYPERVISOR_FAIL,
    ADD_HYPERVISOR_SUCCESS,
    ADD_HYPERVISOR_FAIL,
    ADD_HYPERVISOR,
    RESET_HYPERVISOR_FLAGS
} from "./actionTypes"

const INIT_STATE = {
    list: [],
    detail: {}, 
    error: {},
    metrics: {},
    storageConfig: {
        disks: []
    }
}

const Hypervisors = (state = INIT_STATE, action) => {
    switch (action.type) {
    case API_RESPONSE_SUCCESS:
        switch (action.payload.actionType) {

        case GET_HYPERVISORS:
            return {
                ...state,
                list: action.payload.data,
            };

        default:
            return { ...state };
        }

    case API_RESPONSE_ERROR:
        switch (action.payload.actionType) {
        case GET_HYPERVISORS:
            return {
                ...state,
                error: action.payload.error,
            };

        default:
            return { ...state };
        }

    case GET_HYPERVISORS: {
        return {
            ...state,
        };
    }

    case GET_HYPERVISOR_DETAIL_SUCCESS:
        return {
            ...state,
            isHypervisorDetailSuccess: true,
            detail: {
                ...state.detail, 
                [action.payload.slug]: action.payload
            },
        }

    case GET_HYPERVISOR_DETAIL_FAIL:
        return {
            ...state,
            error: action.payload,
        }

    case GET_HYPERVISOR_METRICS_SUCCESS:
        return {
            ...state,
            metrics: action.payload,
        }

    case GET_HYPERVISOR_METRICS_FAIL:
        return {
            ...state,
            error: action.payload,
        }

    case GET_HYPERVISOR_STORAGE_SUCCESS:
        return {
            ...state,
            storageConfig: action.payload,
        }

    case GET_HYPERVISOR_STORAGE_FAIL:
        return {
            ...state,
            error: action.payload,
        }

    case UPDATE_HYPERVISOR_SUCCESS:
        return {
            ...state,
            hypervisorsList: state.hypervisorsList.map(hypervisor =>
                hypervisor.id.toString() === action.payload.id.toString()
                    ? { hypervisor, ...action.payload }
                    : hypervisor
            ),
        }

    case UPDATE_HYPERVISOR_FAIL:
        return {
            ...state,
            error: action.payload,
        }

    case UPDATE_HYPERVISOR_STORAGE_SUCCESS:
        return {
            ...state,
            isHypervisorStorageUpdated: true,
        };

    case UPDATE_HYPERVISOR_STORAGE_FAIL:
        return {
            ...state,
            error: action.payload,
            isHypervisorStorageUpdated: false
        };

    case DESTROY_HYPERVISOR_STORAGE_SUCCESS:
        return {
            ...state,
        };

    case DESTROY_HYPERVISOR_STORAGE_FAIL:
        return {
            ...state,
            error: action.payload,
        };

    case ADD_HYPERVISOR:
        return {
            ...state,
            error: {},
            inProgress: true
        }  
    
    case ADD_HYPERVISOR_SUCCESS:
        return {
            ...state,
            isHypervisorCreated: true,
            inProgress: false
        };

    case ADD_HYPERVISOR_FAIL:
        return {
            ...state,
            error: action.payload,
            isHypervisorCreated: false,
            inProgress: false
        };

    case RESET_HYPERVISOR_FLAGS:
        return {
            ...state,
            error: {},
            isHypervisorCreated: undefined,
            inProgress: undefined
        };

    default:
        return state
    }
}

export default Hypervisors

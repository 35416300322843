import axios from "axios";

// default
axios.defaults.baseURL = "";
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// content type
const token = JSON.parse(localStorage.getItem("authUser")) ? JSON.parse(localStorage.getItem("authUser")).accessToken : null;
if(token)
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

// intercepting to capture errors
axios.interceptors.response.use(
    function (response) {
        return response.data ? response.data : response;
    },
    function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;
        switch (error.response.status) {
        case 401:
            message = "Invalid credentials";
            localStorage.removeItem('authUser'); 
            window.location = '/login';
            break;
        default:
            message = error;
        }
        return Promise.reject(message);
    }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
    /**
   * Fetches data from given url
   */

    //  get = (url, params) => {
    //   return axios.get(url, params);
    // };
    get = (url, params) => {
        let response;

        if (params) {
            const paramKeys = params ? Object.keys(params).map(key => `${key}=${params[key]}`) : [];
            const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
            response = axios.get(`${url}?${queryString}`, params);
        } else {
            response = axios.get(`${url}`, params);
        }

        return response;
    };
    /**
   * post given data to url
   */
    create = (url, data, params) => {
        let response;

        if (params) {
            const paramKeys = params ? Object.keys(params).map(key => `${key}=${params[key]}`) : [];
            const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
            response = axios.post(`${url}?${queryString}`, data);
        } else {
            response = axios.post(url, data);
        }

        return response;
    };
    /**
   * Updates data
   */
    update = (url, data) => {
        return axios.patch(url, data);
    };

    put = (url, data) => {
        return axios.put(url, data);
    };
    /**
   * Delete
   */
    delete = (url, config) => {
        return axios.delete(url, { ...config });
    };
}
const getLoggedinUser = () => {
    const user = localStorage.getItem("authUser");
    if (!user) {
        return null;
    } else {
        return JSON.parse(user);
    }
};

export { APIClient, setAuthorization, getLoggedinUser };
/*  GET TEMPLATES */

export const GET_TEMPLATES_REQUEST = "GET_TEMPLATES_REQUEST"
export const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS"
export const GET_TEMPLATES_FAIL = "GET_TEMPLATES_FAIL"

export const GET_TEMPLATE_DETAIL_REQUEST = "GET_TEMPLATE_DETAIL_REQUEST"
export const GET_TEMPLATE_DETAIL_SUCCESS = "GET_TEMPLATE_DETAIL_SUCCESS"
export const GET_TEMPLATE_DETAIL_FAIL = "GET_TEMPLATE_DETAIL_FAIL"

/**
* Add TEMPLATE
*/
export const CREATE_TEMPLATE_REQUEST = "CREATE_TEMPLATE_REQUEST";
export const CREATE_TEMPLATE_SUCCESS = "CREATE_TEMPLATE_SUCCESS";
export const CREATE_TEMPLATE_FAIL = "CREATE_TEMPLATE_FAIL";

/**
* Add DISK to TEMPLATE
*/
export const CREATE_TEMPLATE_DISK_REQUEST = "CREATE_TEMPLATE_DISK_REQUEST";
export const CREATE_TEMPLATE_DISK_SUCCESS = "CREATE_TEMPLATE_DISK_SUCCESS";
export const CREATE_TEMPLATE_DISK_FAIL = "CREATE_TEMPLATE_DISK_FAIL";

export const DELETE_TEMPLATE_DISK_REQUEST = "DELETE_TEMPLATE_DISK_REQUEST";
export const DELETE_TEMPLATE_DISK_SUCCESS = "DELETE_TEMPLATE_DISK_SUCCESS";
export const DELETE_TEMPLATE_DISK_FAIL = "DELETE_TEMPLATE_DISK_FAIL";

export const GET_TEMPLATE_DISK_REQUEST = "GET_TEMPLATE_DISK_REQUEST"
export const GET_TEMPLATE_DISK_SUCCESS = "GET_TEMPLATE_DISK_SUCCESS"
export const GET_TEMPLATE_DISK_FAIL = "GET_TEMPLATE_DISK_FAIL"


/**
* UPDATE TEMPLATE
*/
export const UPDATE_TEMPLATE_REQUEST = "UPDATE_TEMPLATE_REQUEST";
export const UPDATE_TEMPLATE_SUCCESS = "UPDATE_TEMPLATE_SUCCESS";
export const UPDATE_TEMPLATE_FAIL = "UPDATE_TEMPLATE_FAIL";

/**
* DELETE TEMPLATE
*/
export const DELETE_TEMPLATE_REQUEST = "DELETE_TEMPLATE_REQUEST";
export const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS";
export const DELETE_TEMPLATE_FAIL = "DELETE_TEMPLATE_FAIL";

export const RESET_TEMPLATE_FLAGS = "RESET_TEMPLATE_FLAGS";

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MarketingPersonalisation } from '../models/MarketingPersonalisation';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MarketingService {

    /**
     * Create a personalisation
     * @param key Response Key
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static setPersonlisation(
        key: string,
        requestBody: MarketingPersonalisation,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/personalisation',
            query: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Get all specific personalisations
     * @param id
     * @returns MarketingPersonalisation OK
     * @throws ApiError
     */
    public static getPersonalisation(
        id: string,
    ): CancelablePromise<MarketingPersonalisation> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/personalisation/get/[id]',
            path: {
                'id': id,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Get all marketing personalisations
     * @param key Response Key
     * @returns MarketingPersonalisation OK
     * @throws ApiError
     */
    public static listPersonalisation(
        key: string,
    ): CancelablePromise<Array<MarketingPersonalisation>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/personalisation/list',
            query: {
                'key': key,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

}

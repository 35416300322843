import {
    GET_VIRTUAL_MACHINES_REQUEST,
    GET_VIRTUAL_MACHINES_SUCCESS,
    GET_VIRTUAL_MACHINES_FAIL,
    ADD_VIRTUAL_MACHINE_SUCCESS,
    ADD_VIRTUAL_MACHINE_FAIL,
    ADD_VIRTUAL_MACHINE,
    GET_VIRTUAL_MACHINE_DETAIL_FAIL,
    GET_VIRTUAL_MACHINE_DETAIL_SUCCESS,
    RESET_VIRTUAL_MACHINE_FLAGS
} from "./actionTypes"
  
const INIT_STATE = {
    list: [],
    detail: {},
    taskInProgess: null,
    error: undefined
}
  
const VirtualMachines = (state = INIT_STATE, action) => {
    switch (action.type) {

    case GET_VIRTUAL_MACHINES_REQUEST: {
        return {
            ...state,
        };
    }

    case GET_VIRTUAL_MACHINES_SUCCESS: {
        return {
            ...state,
            list: action.payload.data.vms,
        };
    }
    case GET_VIRTUAL_MACHINES_FAIL: {
        return {
            ...state,
            error: action.payload.error,
        };
    }

    case ADD_VIRTUAL_MACHINE: 
        return {
            ...state,
            error: null,
            taskInProgess: true,
        };

    case ADD_VIRTUAL_MACHINE_SUCCESS:
        return {
            ...state,
            isVirtualMachineCreated: true,
            isVirtualMachineCreated: true,
            isVMAddFail: false,
            taskInProgess: false,
        }
          

    case ADD_VIRTUAL_MACHINE_FAIL:
        return {
            ...state,
            error: action.payload,
            isVirtualMachineCreated: false,
            isVMAddFail: true,
            taskInProgess: false,
        };

    case GET_VIRTUAL_MACHINE_DETAIL_SUCCESS:
        return {
            ...state,
            getVirtualMachineDetailSuccess: true,
            detail: {
                ...state.detail, 
                [action.payload.slug]: action.payload
            },
        }
    
    case GET_VIRTUAL_MACHINE_DETAIL_FAIL:
        return {
            ...state,
            error: action.payload,
        }

    case RESET_VIRTUAL_MACHINE_FLAGS:
        return {
            ...state,
            error: null,
        };  


    default:
        return state
    }
}
  
export default VirtualMachines
  
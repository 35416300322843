import React, { useCallback, useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import {map} from 'lodash'
import { Link } from 'react-router-dom';

const URLComponent = (cell) => {
 
    const [isCopied, setIsCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(`https://www.gallium.cloud/plp/${cell.value}`).then(() => {
            setIsCopied(true);

            // Reset the copied state after 3 seconds
            setTimeout(() => setIsCopied(false), 3000);
        });
    };

    return (
        <React.Fragment>
            <Button color='danger'className="btn-label right btn-sm" onClick={copyToClipboard}>
                <i className="ri-file-copy-line label-icon align-middle fs-16 ms-2"></i>
                {isCopied ? "Copied!" : "Copy Live URL"}
            </Button>
            <Link className="btn btn-primary btn-label right btn-sm ms-2" target="_blank" to={"https://www.gallium.cloud/tlp/" + cell.value}><i className="ri-external-link-line label-icon align-middle fs-16 ms-2"></i>Vist Test Page</Link>
        </React.Fragment>
    );
};

export default URLComponent;

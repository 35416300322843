import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


//  Redux States
import { 
    GET_HYPERVISORS,
    GET_HYPERVISOR_DETAIL_REQUEST, 
    GET_HYPERVISOR_METRICS,
    GET_HYPERVISOR_STORAGE,
    UPDATE_HYPERVISOR_STORAGE,
    DESTROY_HYPERVISOR_STORAGE_REQUEST,
    ADD_HYPERVISOR
} from "./actionTypes"
import {
    HypervisorsApiResponseSuccess,
    HypervisorsApiResponseError,
    getHypervisorDetailSuccess,
    getHypervisorDetailFail,
    getHypervisorMetricsSuccess,
    getHypervisorMetricsFail,
    getHypervisorStorageSuccess,
    getHypervisorStorageFail,
    updateHypervisorStorageSuccess,
    updateHypervisorStorageFail,
    destroyHypervisorStorageSuccess,
    destroyHypervisorStorageFail,
    addHypervisorSuccess,
    addHypervisorFail
} from "./actions"

//Include Both Helper File with needed methods
import { 
    getHypervisors,
    getHypervisorDetail,
    getHypervisorMetrics,
    getHypervisorStorage,
    updateHypervisorStorage,
    destroyHypervisorStorage,
    addHypervisor
} from "helpers/galliumbackend_helper"

function* onGetHypervisorList() {
    try {
        const response = yield call(getHypervisors)
        yield put(HypervisorsApiResponseSuccess(GET_HYPERVISORS, response))
    } catch (error) {
        yield put(HypervisorsApiResponseError(GET_HYPERVISORS, error))
    }
}

function* onGetHypervisorDetail({ hypervisorId }) {
    try {
        const response = yield call(getHypervisorDetail, hypervisorId)
        yield put(getHypervisorDetailSuccess(response))
    } catch (error) {
        yield put(getHypervisorDetailFail(error))
    }
}

function* onGetHypervisorMetrics({ hypervisorId }) {
    try {
        const response = yield call(getHypervisorMetrics, hypervisorId)
        yield put(getHypervisorMetricsSuccess(response))
    } catch (error) {
        yield put(getHypervisorMetricsFail(error))
    }
}

function* onGetHypervisorStorage({ hypervisorId }) {
    try {
        const response = yield call(getHypervisorStorage, hypervisorId)
        yield put(getHypervisorStorageSuccess(response))
    } catch (error) {
        yield put(getHypervisorStorageFail(error))
    }
}

function* onUpdateHypervisorStorage({ storageConfig, hypervisor, history }) {
    try {
        const response = yield call(updateHypervisorStorage, storageConfig, hypervisor);
        yield put(updateHypervisorStorageSuccess(response));
        toast.info("Storage Pool Configuration Started", { autoClose: 3000 });
        history(`/hypervisors/` + response.command.hostSlug)
    } catch (error) {
        yield put(updateHypervisorStorageFail(error));
        toast.error("Storage Configuration Failed", { autoClose: 3000 });
    }
}

function* onDestroyHypervisorStorage({ storageConfig, hypervisor, history }) {
    try {
        const response = yield call(destroyHypervisorStorage, storageConfig, hypervisor);
        yield put(destroyHypervisorStorageSuccess(response));
        toast.info("Storage Pool Destruction Started", { autoClose: 3000 });
        history(`/hypervisors/` + response.command.hostSlug)
    } catch (error) {
        yield put(destroyHypervisorStorageFail(error));
        toast.error("Storage Pool Destruction Failed", { autoClose: 3000 });
    }
}

function* onAddHypervisor({ newHypervisor }) {
    try {
        const response = yield call(addHypervisor, newHypervisor);
        yield put(addHypervisorSuccess(response));
        toast.success("Hypervisor Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(addHypervisorFail(error));
        toast.error("Hypervisor Added Failed", { autoClose: 3000 });
    }
}

export function* watchGetHypervisorsList() {
    yield takeEvery(GET_HYPERVISORS, onGetHypervisorList);
}

export function* watchGetHypervisorDetail() {
    yield takeEvery(GET_HYPERVISOR_DETAIL_REQUEST, onGetHypervisorDetail);
}

export function* watchGetHypervisorMetrics() {
    yield takeEvery(GET_HYPERVISOR_METRICS, onGetHypervisorMetrics);
}

export function* watchGetHypervisorStorage() {
    yield takeEvery(GET_HYPERVISOR_STORAGE, onGetHypervisorStorage);
}

export function* watchUpdateHypervisorStorage() {
    yield takeEvery(UPDATE_HYPERVISOR_STORAGE, onUpdateHypervisorStorage);
}

export function* watchDestroyHypervisorStorage() {
    yield takeEvery(DESTROY_HYPERVISOR_STORAGE_REQUEST, onDestroyHypervisorStorage);
}

export function* watchAddHypervisor() {
    yield takeEvery(ADD_HYPERVISOR, onAddHypervisor);
}

function* HypervisorsSaga() {
    yield all([
        fork(watchGetHypervisorsList),
        fork(watchGetHypervisorDetail),
        fork(watchGetHypervisorMetrics),
        fork(watchGetHypervisorStorage),
        fork(watchUpdateHypervisorStorage),
        fork(watchDestroyHypervisorStorage),
        fork(watchAddHypervisor),
    ]
    );
}

export default HypervisorsSaga

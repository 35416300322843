import React from 'react';

interface ErrorNoticeProps {
    text?: string;
}

const ErrorNotice: React.FC<ErrorNoticeProps> = ({ text }) => {
    return (
        <React.Fragment>
            <div className='text-center p-5'>
                <h6 color="danger">{text || "Something has gone wrong"}</h6>
            </div>
        </React.Fragment>
    )
};

export default ErrorNotice;
import {
    GET_TEMPLATES_REQUEST,
    GET_TEMPLATES_SUCCESS,
    GET_TEMPLATES_FAIL,
    GET_TEMPLATE_DETAIL_REQUEST,
    GET_TEMPLATE_DETAIL_SUCCESS,
    GET_TEMPLATE_DETAIL_FAIL,
    GET_TEMPLATE_DISK_REQUEST,
    GET_TEMPLATE_DISK_SUCCESS,
    GET_TEMPLATE_DISK_FAIL,
    CREATE_TEMPLATE_REQUEST,
    CREATE_TEMPLATE_SUCCESS,
    CREATE_TEMPLATE_FAIL,
    CREATE_TEMPLATE_DISK_REQUEST,
    CREATE_TEMPLATE_DISK_SUCCESS,
    CREATE_TEMPLATE_DISK_FAIL,
    DELETE_TEMPLATE_DISK_REQUEST,
    DELETE_TEMPLATE_DISK_SUCCESS,
    DELETE_TEMPLATE_DISK_FAIL,
    UPDATE_TEMPLATE_REQUEST,
    UPDATE_TEMPLATE_SUCCESS,
    UPDATE_TEMPLATE_FAIL,
    DELETE_TEMPLATE_REQUEST,
    DELETE_TEMPLATE_SUCCESS,
    DELETE_TEMPLATE_FAIL,
    RESET_TEMPLATE_FLAGS
} from "./actionTypes"
  
const INIT_STATE = {
    all: [],
    detail: {},
    error: undefined,
}
  
const Templates = (state = INIT_STATE, action) => {
    switch (action.type) {

    case GET_TEMPLATES_REQUEST: {
        return {
            ...state,
        };
    }

    case GET_TEMPLATES_SUCCESS: {
        return {
            ...state,
            all: action.payload.data.templates,
        };
    }
    case GET_TEMPLATES_FAIL: {
        return {
            ...state,
            error: action.payload.error,
        };
    }

    case GET_TEMPLATE_DETAIL_REQUEST: {
        return {
            ...state,
        };
    }

    case GET_TEMPLATE_DETAIL_SUCCESS: {
        return {
            ...state,
            detail: {
                ...state.detail, 
                [action.payload.slug]: action.payload
            },
        };
    }
    case GET_TEMPLATE_DETAIL_FAIL: {
        return {
            ...state,
            error: action.payload.error,
        };
    }

    case GET_TEMPLATE_DISK_REQUEST: {
        return {
            ...state,
        };
    }

    case GET_TEMPLATE_DISK_SUCCESS: {
        const updatedDisk = action.payload;
        const { templateSlug, slug } = updatedDisk;

        const templateDetail = state.detail[templateSlug];

        // Find the index of the disk with the matching slug
        const diskIndex = state.detail[templateSlug].disks.findIndex(
            (disk) => disk.slug === slug
        );
        
        // If the disk doesn't exist in the array, add it
        if (diskIndex === -1) {
            return {
                ...state,
                detail: {
                    ...state.detail, 
                    [templateSlug]: {
                        ...templateDetail,
                        disks: [...templateDetail.disks, updatedDisk],
                    },
                },
            };
        }

        // Otherwise, replace the existing disk with the updated one
        return {
            ...state,
            detail: {
                ...state.detail,
                [templateSlug]: {
                    ...templateDetail,
                    disks: templateDetail.disks.map((disk, index) =>
                        index !== diskIndex ? disk : { ...disk, ...action.payload }
                    ),
                },
            },
        }
    }

    case GET_TEMPLATE_DISK_FAIL: {
        return {
            ...state,
            error: action.payload.error,
        };
    }

    case CREATE_TEMPLATE_REQUEST: 
        return {
            ...state,
            error: undefined,
        };

    case CREATE_TEMPLATE_SUCCESS:
        return {
            ...state,
            detail: {
                ...state.detail, 
                [action.payload.data.slug]: action.payload.data
            },
        }
        
    case CREATE_TEMPLATE_FAIL:
        return {
            ...state,
            error: action.payload,
        };

    case CREATE_TEMPLATE_DISK_REQUEST: 
        return {
            ...state,
            error: undefined,
        };

    case CREATE_TEMPLATE_DISK_SUCCESS: {

        const updatedDisk = action.payload;
        const { templateSlug, slug } = updatedDisk;

        const templateDetail = state.detail[templateSlug];

        // Find the index of the disk with the matching slug
        let diskIndex = -1;
        if (state.detail[templateSlug] && state.detail[templateSlug].disks) {
            diskIndex = state.detail[templateSlug].disks.findIndex(
                (disk) => disk.slug === slug
            );
        }
        
        // If the disk doesn't exist in the array, add it
        if (diskIndex === -1) {
            return {
                ...state,
                detail: {
                    ...state.detail, 
                    [templateSlug]: {
                        ...templateDetail,
                        disks: [...templateDetail.disks, updatedDisk],
                    },
                },
            };
        }

        // Otherwise, replace the existing disk with the updated one
        return {
            ...state,
            detail: {
                ...state.detail,
                [templateSlug]: {
                    ...templateDetail,
                    disks: templateDetail.disks.map((disk, index) =>
                        index !== diskIndex ? disk : { ...disk, ...action.payload }
                    ),
                },
            },
        }
    }    

    case CREATE_TEMPLATE_DISK_FAIL:
        return {
            ...state,
            error: action.payload,
        };

    case DELETE_TEMPLATE_DISK_REQUEST: 
        return {
            ...state,
            error: undefined,
        };

    case DELETE_TEMPLATE_DISK_SUCCESS: {
        const deletedDisk = action.disk;
        const { templateSlug, slug } = deletedDisk;

        const templateDetail = state.detail[templateSlug];

        if (state.detail[templateSlug] && state.detail[templateSlug].disks) {
            return {
                ...state,
                detail: {
                    ...state.detail,
                    [action.disk.templateSlug]: {
                        ...templateDetail,
                        disks: templateDetail.disks.filter((disk) =>
                            disk.slug !== slug
                        ),
                    },
                },
            }
        } else {
            return state;
        }
    }    

    case DELETE_TEMPLATE_DISK_FAIL:
        return {
            ...state,
            error: action.payload,
        };    

    case UPDATE_TEMPLATE_REQUEST: 
        return {
            ...state,
            error: undefined,
        };

    case UPDATE_TEMPLATE_SUCCESS:
        return {
            ...state,
            detail: {
                ...state.detail, 
                [action.payload.slug]: action.payload
            },
        }
        
    case UPDATE_TEMPLATE_FAIL:
        return {
            ...state,
            error: action.payload,
        };

    case DELETE_TEMPLATE_REQUEST: 
        return {
            ...state,
            error: null,
        };

    case DELETE_TEMPLATE_SUCCESS:
        return {
            ...state,
            // Should I remove the template from the redux store?
        }
        
    case DELETE_TEMPLATE_FAIL:
        return {
            ...state,
            error: action.payload,
        };       

    case RESET_TEMPLATE_FLAGS:
        return {
            ...state,
            error: undefined,
        };

    default:
        return state
    }
}
  
export default Templates
  
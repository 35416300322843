/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum VmPowerStateTransition {
    START = 'START',
    SHUTDOWN = 'SHUTDOWN',
    HALT = 'HALT',
    REBOOT = 'REBOOT',
    RESET = 'RESET',
}

import {
    GET_VNC_URL_FAIL,
    GET_VNC_URL_SUCCESS,
    RESET_VNC_URL_FLAGS
} from "./actionTypes"

const INIT_STATE = {
    url: "",
    error: undefined,
}

const vnc_url = (state = INIT_STATE, action) => {
    switch (action.type) {
    case GET_VNC_URL_SUCCESS:
      
        return {
            ...state,
            url: action.payload.url,
        }
    case GET_VNC_URL_FAIL:
        return {
            ...state,
            error: action.payload,
        }

    case RESET_VNC_URL_FLAGS:
        return {
            ...state,
            error: undefined,
            url: ""
        }
  

    default:
        return state
    }
}

export default vnc_url

import {
    GET_HYPERVISORS,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    GET_HYPERVISOR_DETAIL_REQUEST,
    GET_HYPERVISOR_DETAIL_SUCCESS,
    GET_HYPERVISOR_DETAIL_FAIL,
    GET_HYPERVISOR_METRICS,
    GET_HYPERVISOR_METRICS_SUCCESS,
    GET_HYPERVISOR_METRICS_FAIL,
    GET_HYPERVISOR_STORAGE,
    GET_HYPERVISOR_STORAGE_SUCCESS,
    GET_HYPERVISOR_STORAGE_FAIL,
    UPDATE_HYPERVISOR_STORAGE,
    UPDATE_HYPERVISOR_STORAGE_SUCCESS,
    UPDATE_HYPERVISOR_STORAGE_FAIL,
    DESTROY_HYPERVISOR_STORAGE_REQUEST,
    DESTROY_HYPERVISOR_STORAGE_SUCCESS,
    DESTROY_HYPERVISOR_STORAGE_FAIL,
    UPDATE_HYPERVISOR,
    UPDATE_HYPERVISOR_SUCCESS,
    UPDATE_HYPERVISOR_FAIL,
    ADD_HYPERVISOR,
    ADD_HYPERVISOR_SUCCESS,
    ADD_HYPERVISOR_FAIL,
    RESET_HYPERVISOR_FLAGS
} from "./actionTypes"

export const getHypervisors = () => ({
    type: GET_HYPERVISORS,
})

// common success
export const HypervisorsApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const HypervisorsApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getHypervisorDetail = hypervisorId => ({
    type: GET_HYPERVISOR_DETAIL_REQUEST,
    hypervisorId,
})

export const getHypervisorDetailSuccess = hypervisorDetail => ({
    type: GET_HYPERVISOR_DETAIL_SUCCESS,
    payload: hypervisorDetail,
})

export const getHypervisorDetailFail = error => ({
    type: GET_HYPERVISOR_DETAIL_FAIL,
    payload: error,
})

export const getHypervisorMetrics = hypervisorId => ({
    type: GET_HYPERVISOR_METRICS,
    hypervisorId,
})

export const getHypervisorMetricsSuccess = hypervisorMetrics => ({
    type: GET_HYPERVISOR_METRICS_SUCCESS,
    payload: hypervisorMetrics,
})

export const getHypervisorMetricsFail = error => ({
    type: GET_HYPERVISOR_METRICS_FAIL,
    payload: error,
})

export const getHypervisorStorage = hypervisorId => ({
    type: GET_HYPERVISOR_STORAGE,
    hypervisorId,
})

export const getHypervisorStorageSuccess = hypervisorStorage => ({
    type: GET_HYPERVISOR_STORAGE_SUCCESS,
    payload: hypervisorStorage,
})

export const getHypervisorStorageFail = error => ({
    type: GET_HYPERVISOR_STORAGE_FAIL,
    payload: error,
})

export const updateHypervisorStorage = (storageConfig, hypervisor, history) => ({
    type: UPDATE_HYPERVISOR_STORAGE,
    storageConfig,
    hypervisor,
    history
});

export const updateHypervisorStorageSuccess = message => ({
    type: UPDATE_HYPERVISOR_STORAGE_SUCCESS,
    payload: message,
});

export const updateHypervisorStorageFail = error => ({
    type: UPDATE_HYPERVISOR_STORAGE_FAIL,
    payload: error.response.data,
});

export const destroyHypervisorStorage = (storageConfig, hypervisor, history) => ({
    type: DESTROY_HYPERVISOR_STORAGE_REQUEST,
    storageConfig,
    hypervisor,
    history
});

export const destroyHypervisorStorageSuccess = message => ({
    type: DESTROY_HYPERVISOR_STORAGE_SUCCESS,
    payload: message,
});

export const destroyHypervisorStorageFail = error => ({
    type: DESTROY_HYPERVISOR_STORAGE_FAIL,
    payload: error.response.data,
});

export const updateHypervisor = hypervisor => ({
    type: UPDATE_HYPERVISOR,
    payload: hypervisor,
})

export const updateHypervisorSuccess = hypervisor => ({
    type: UPDATE_HYPERVISOR_SUCCESS,
    payload: hypervisor,
})

export const updateHypervisorFail = error => ({
    type: UPDATE_HYPERVISOR_FAIL,
    payload: error,
})

export const addHypervisor = newHypervisor => ({
    type: ADD_HYPERVISOR,
    newHypervisor
});

export const addHypervisorSuccess = hypervisor => ({
    type: ADD_HYPERVISOR_SUCCESS,
    payload: hypervisor,
});

export const addHypervisorFail = error => ({
    type: ADD_HYPERVISOR_FAIL,
    payload: error.response.data,
});

export const resetHypervisorFlags = () => {
    return {
        type: RESET_HYPERVISOR_FLAGS,
    }
}
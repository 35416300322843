import { combineReducers } from "redux"

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"


//hypervisors
import Hypervisors from "./hypervisors/reducer"

//VmViewer
import vnc_url from "./vnc/reducer"

//SSH Keys
import SSHKeys from "./inventory/reducer"

import VirtualMachines from "./vm/reducer"

import Templates from "./templates/reducer"

import uiReducer from "./uiActions/reducer";

const rootReducer = combineReducers({
    // public
    Layout,
    Login,
    Account,
    Hypervisors,
    vnc_url,
    SSHKeys,
    VirtualMachines,
    Templates,
    uiReducer
})

export default rootReducer

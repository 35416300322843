import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//  Redux States
import { 
    GET_TEMPLATES_REQUEST,
    GET_TEMPLATE_DETAIL_REQUEST,
    GET_TEMPLATE_DISK_REQUEST,
    CREATE_TEMPLATE_REQUEST,
    CREATE_TEMPLATE_DISK_REQUEST,
    DELETE_TEMPLATE_DISK_REQUEST,
    UPDATE_TEMPLATE_REQUEST,
    DELETE_TEMPLATE_REQUEST
} from "./actionTypes"
import {
    getTemplatesSuccess,
    getTemplatesFail,
    getTemplateDetailSuccess,
    getTemplateDetailFail,
    getTemplateDiskSuccess,
    getTemplateDiskFail,
    createTemplateSuccess,
    createTemplateFail,
    createTemplateDiskSuccess,
    createTemplateDiskFail,
    deleteTemplateDiskSuccess,
    deleteTemplateDiskFail,
    updateTemplateSuccess,
    updateTemplateFail,
    deleteTemplateSuccess,
    deleteTemplateFail,
} from "./actions"

//Include Both Helper File with needed methods
import { 
    getTemplates,
    getTemplateDetail,
    getTemplateDisk,
    createTemplate,
    createTemplateDisk,
    deleteTemplateDisk,
    updateTemplate,
    deleteTemplate
} from "helpers/galliumbackend_helper"

function* onGetTemplates({data}) {
    try {
        const response = yield call(getTemplates, data)
        yield put(getTemplatesSuccess(response))
    } catch (error) {
        yield put(getTemplatesFail(error))
    }
}

function* onGetTemplateDetail({ templateSlug }) {
    try {
        const response = yield call(getTemplateDetail, templateSlug)
        yield put(getTemplateDetailSuccess(response))
    } catch (error) {
        yield put(getTemplateDetailFail(error))
    }
}

function* onGetTemplateDisk({ templateSlug, diskSlug }) {
    try {
        const response = yield call(getTemplateDisk, templateSlug, diskSlug)
        yield put(getTemplateDiskSuccess(response))
    } catch (error) {
        yield put(getTemplateDiskFail(error))
    }
}

function* onCreateTemplate({ data, history }) {
    try {
        const response = yield call(createTemplate, data);
        yield put(createTemplateSuccess(response));
        toast.success("Draft Template Created", { autoClose: 3000 });
        history(`/inventory/templates/create?template=` + response.slug);
    } catch (error) {
        yield put(createTemplateFail(error));
        toast.error("Template Creation Failed", { autoClose: 3000 });
    }
}

function* onCreateTemplateDisk({ templateSlug, data }) {
    try {
        const response = yield call(createTemplateDisk, templateSlug, data);
        yield put(createTemplateDiskSuccess(response));
        toast.success("Disk Added to Template Draft", { autoClose: 3000 });
    } catch (error) {
        yield put(createTemplateDiskFail(error));
        toast.error("Disk Add Failed", { autoClose: 3000 });
    }
}

function* onDeleteTemplateDisk({ disk }) {
    try {
        const response = yield call(deleteTemplateDisk, disk);
        yield put(deleteTemplateDiskSuccess(response, disk));
        toast.success("Disk removed from template", { autoClose: 3000 });
    } catch (error) {
        yield put(deleteTemplateDiskFail(error));
        toast.error("Disk could not be removed", { autoClose: 3000 });
    }
}

function* onUpdateTemplate({ templateSlug, data, history }) {
    try {
        const response = yield call(updateTemplate, templateSlug, data);
        yield put(updateTemplateSuccess(response));
        toast.success("Template Updated", { autoClose: 3000 });
        history(`/inventory/templates/`);
    } catch (error) {
        yield put(updateTemplateFail(error));
        toast.error("Template Update Failed", { autoClose: 3000 });
    }
}

function* onDeleteTemplate({ templateSlug, history }) {
    try {
        const response = yield call(deleteTemplate, templateSlug);
        yield put(deleteTemplateSuccess(response));
        toast.success("Template Deleted", { autoClose: 3000 });
        history(`/inventory/templates/`);
    } catch (error) {
        yield put(deleteTemplateFail(error));
        toast.error("Template Deletion Failed", { autoClose: 3000 });
    }
}

export function* watchGetTemplates() {
    yield takeEvery(GET_TEMPLATES_REQUEST, onGetTemplates);
}

export function* watchGetTemplateDetail() {
    yield takeEvery(GET_TEMPLATE_DETAIL_REQUEST, onGetTemplateDetail);
}

export function* watchGetTemplateDisk() {
    yield takeEvery(GET_TEMPLATE_DISK_REQUEST, onGetTemplateDisk);
}

export function* watchCreateTemplate() {
    yield takeEvery(CREATE_TEMPLATE_REQUEST, onCreateTemplate);
}

export function* watchCreateTemplateDisk() {
    yield takeEvery(CREATE_TEMPLATE_DISK_REQUEST, onCreateTemplateDisk);
}

export function* watchDeleteTemplateDisk() {
    yield takeEvery(DELETE_TEMPLATE_DISK_REQUEST, onDeleteTemplateDisk);
}

export function* watchUpdateTemplate() {
    yield takeEvery(UPDATE_TEMPLATE_REQUEST, onUpdateTemplate);
}

export function* watchDeleteTemplate() {
    yield takeEvery(DELETE_TEMPLATE_REQUEST, onDeleteTemplate);
}

function* TemplatesSaga() {
    yield all([
        fork(watchGetTemplates),
        fork(watchGetTemplateDetail),
        fork(watchGetTemplateDisk),
        fork(watchCreateTemplate),
        fork(watchCreateTemplateDisk),
        fork(watchDeleteTemplateDisk),
        fork(watchUpdateTemplate),
        fork(watchDeleteTemplate),
    ]
    );
}

export default TemplatesSaga


import {
    GET_VIRTUAL_MACHINES_REQUEST,
    GET_VIRTUAL_MACHINES_SUCCESS,
    GET_VIRTUAL_MACHINES_FAIL,
    ADD_VIRTUAL_MACHINE,
    ADD_VIRTUAL_MACHINE_SUCCESS,
    ADD_VIRTUAL_MACHINE_FAIL,
    CHANGE_VIRTUAL_MACHINE_STATE_REQUEST,
    CHANGE_VIRTUAL_MACHINE_STATE_SUCCESS,
    CHANGE_VIRTUAL_MACHINE_STATE_FAIL,
    GET_VIRTUAL_MACHINE_DETAIL_REQUEST,
    GET_VIRTUAL_MACHINE_DETAIL_SUCCESS,
    GET_VIRTUAL_MACHINE_DETAIL_FAIL,
    RESET_VIRTUAL_MACHINE_FLAGS
} from "./actionTypes"


export const getVirtualMachinesSuccess = (actionType, data) => ({
    type: GET_VIRTUAL_MACHINES_SUCCESS,
    payload: { actionType, data },
});

export const getVirtualMachinesFail = (actionType, error) => ({
    type: GET_VIRTUAL_MACHINES_FAIL,
    payload: { actionType, error },
});
  
export const getVirtualMachines = () => ({
    type: GET_VIRTUAL_MACHINES_REQUEST,
})
  
export const addVirtualMachine = (data, hypervisor, history) => ({
    type: ADD_VIRTUAL_MACHINE,
    data,
    hypervisor,
    history
});
  
export const addVirtualMachineSuccess = vm => ({
    type: ADD_VIRTUAL_MACHINE_SUCCESS,
    payload: vm,
});
  
export const addVirtualMachineFail = error => ({
    type: ADD_VIRTUAL_MACHINE_FAIL,
    payload: error.response.data,
});

export const changeVirtualMachineState = (vm, requestedState) => ({
    type: CHANGE_VIRTUAL_MACHINE_STATE_REQUEST,
    vm,
    payload: { newState: requestedState }
});
  
export const changeVirtualMachineStateSuccess = vm => ({
    type: CHANGE_VIRTUAL_MACHINE_STATE_SUCCESS,
    vm
});
  
export const changeVirtualMachineStateFail = error => ({
    type: CHANGE_VIRTUAL_MACHINE_STATE_FAIL,
    payload: error.response.data,
});

export const getVirtualMachineDetail = virtualMachineId => ({
    type: GET_VIRTUAL_MACHINE_DETAIL_REQUEST,
    virtualMachineId,
})
  
export const getVirtualMachineDetailSuccess = virtualMachineDetail => ({
    type: GET_VIRTUAL_MACHINE_DETAIL_SUCCESS,
    payload: virtualMachineDetail,
})
  
export const getVirtualMachineDetailFail = error => ({
    type: GET_VIRTUAL_MACHINE_DETAIL_FAIL,
    payload: error,
})
  
export const resetVirtualMachineFlags = () => ({
    type: RESET_VIRTUAL_MACHINE_FLAGS,
})

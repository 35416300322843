import React, { useCallback, useState } from 'react';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import {map} from 'lodash'
import { Link } from 'react-router-dom';

const ShowAllKeysModal = (cell) => {
 
    const [showViewModal, setShowViewModal] = useState<boolean | undefined>(false);

    const toggle = useCallback(() => {
        if (showViewModal) {
            setShowViewModal(false);
        } else {
            setShowViewModal(true);
        }
    }, [showViewModal]);

    return (
        <React.Fragment>
            <Modal
                isOpen={showViewModal}
                toggle={toggle}
                centered
                size="md"
                className="border-0"
                modalClassName='modal fade zoomIn'
            >
                <ModalHeader className="p-3 bg-soft-info" toggle={toggle}>
                View Record
                </ModalHeader>
                <ModalBody className="modal-body">
                    <Row className="g-3">
                        <Col lg={12}>
                            {map(cell.row.original, (value, key) => (
                                <p key={key}>
                                    <h6 className="mb-3 fw-semibold">{key}</h6>
                                    <p>{value || "Unknown"}</p>
                                </p>
                            ))}
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Link to="#" className="fw-medium link-primary" onClick={() => { toggle() }}>{cell.value}</Link>
        </React.Fragment>
    );
};

export default ShowAllKeysModal;
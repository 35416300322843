
const uiReducer = (state = {}, action) => {
    const { type } = action;
    const matches = /(.*)_(REQUEST|SUCCESS|FAIL)/.exec(type);
  
    // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
    if (!matches) {
        return state;
    }
    
    const [requestName, requestPrefix, requestState] = matches;
    
    return {
        ...state,
        // Store whether a request is happening at the moment or not
        // e.g. will be true when receiving FETCH_NEWS_REQUEST
        //      and false when receiving FETCH_NEWS_SUCCESS / FETCH_NEWS_ERROR
        [requestPrefix]: requestState === 'REQUEST'
    };
};
  
export default uiReducer;
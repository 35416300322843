import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import BreadCrumb from '../../../Components/Common/Breadcrumb';

import { Row, Card, CardBody, Container, Col } from 'reactstrap';

import * as Yup from "yup";

import { Field, FormikProvider, useFormik } from "formik";
import { GalliumApiErrorResponse, MarketingPersonalisation } from 'generated';
import { toast } from 'react-toastify';
import { GalliumFormHeader, GalliumInput, GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import ErrorAlert from 'Components/Gallium/ErrorHelper';

import { useSetPersonlisation } from 'GalliumAPIHooks/Marketing/MarketingHooks';

const AddPersonalisation = () => {
    const { trigger, isMutating } = useSetPersonlisation("ToTheMoon111")
    const navigate = useNavigate();

    const [errorObject, setErrorObject] = useState(undefined)

    const handleAddPersonalisationFail = (error: GalliumApiErrorResponse) => {
        addPersonalisationFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleAddPersonalisationSuccess = () => {
        toast.success("Alert Profile Created")
        navigate("/landingpage");
    }

    const handleAddPersonalisationRequest = (values: MarketingPersonalisation) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleAddPersonalisationFail(error)
            },
            onSuccess() {
                handleAddPersonalisationSuccess()
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    const addPersonalisationFormik = useFormik({
        initialValues: {
            userEmail: '',
            userName: '',
            websiteTextHeader: '',
            websiteTextBody: '',
            userVideo: '',
        },
        validationSchema: Yup.object({
        }),
        onSubmit: (values) => {
            handleAddPersonalisationRequest(values)
        },
    });


    console.log(addPersonalisationFormik.values)


    document.title = "Add New Landing Page | Gallium";
    return (    
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="New Personalisation" pageTitle="Marketing Toolkit" parentLink='/personalisation'/>
                    <Row>
                        <FormikProvider value={addPersonalisationFormik}>
                            <Card>
                                <CardBody className="ps-1 pe-1">
                                    <ErrorAlert errorObj={errorObject} />
                            
                                    <GalliumFormHeader icon="navigation" title="Landing Page Settings">
                                        <Col md={6} className="mb-3">
                                            <Field
                                                name="userEmail"
                                                id="userEmail"
                                                placeholder="What is email"
                                                type="text"
                                                label="User Email"
                                                component={GalliumInput}
                                            />
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <Field
                                                name="userName"
                                                id="userName"
                                                placeholder="Who is this"
                                                type="text"
                                                label="User Name"
                                                component={GalliumInput}
                                            />
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <Field
                                                name="websiteTextHeader"
                                                id="websiteTextHeader"
                                                placeholder="Salesy Nonsense"
                                                type="text"
                                                label="Text Header"
                                                component={GalliumInput}
                                            />
                                        </Col>
                                        <Col md={12} className="mb-3">
                                            <Field
                                                name="websiteTextBody"
                                                id="websiteTextBody"
                                                placeholder="More Salesy Nonsense"
                                                type="textarea"
                                                label="Text Body"
                                                component={GalliumInput}
                                            />
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <Field
                                                name="userVideo"
                                                id="userVideo"
                                                placeholder="UUID from Video Embed"
                                                type="text"
                                                label="User Video"
                                                component={GalliumInput}
                                            />
                                        </Col>
                                    </GalliumFormHeader>
                                        
                                
                                    <GalliumSubmitButton className="float-end" formik={addPersonalisationFormik} spinner={isMutating} color="success">
                                        Add Landing Page
                                    </GalliumSubmitButton>
                                </CardBody>
                            </Card>
                        </FormikProvider>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddPersonalisation;
/*  GET VIRTUAL_MACHINES */

export const GET_VIRTUAL_MACHINES_REQUEST = "GET_VIRTUAL_MACHINES_REQUEST"
export const GET_VIRTUAL_MACHINES_SUCCESS = "GET_VIRTUAL_MACHINES_SUCCESS"
export const GET_VIRTUAL_MACHINES_FAIL = "GET_VIRTUAL_MACHINES_FAIL"


/**
* Add VIRTUAL_MACHINE
*/
export const ADD_VIRTUAL_MACHINE = "ADD_NEW_VIRTUAL_MACHINE";
export const ADD_VIRTUAL_MACHINE_SUCCESS = "ADD_VIRTUAL_MACHINE_SUCCESS";
export const ADD_VIRTUAL_MACHINE_FAIL = "ADD_VIRTUAL_MACHINE_FAIL";


/**
* CHANGE VIRTUAL_MACHINE STATE
*/
export const CHANGE_VIRTUAL_MACHINE_STATE_REQUEST = "CHANGE_NEW_VIRTUAL_MACHSTATE_INE_REQUEST";
export const CHANGE_VIRTUAL_MACHINE_STATE_SUCCESS = "CHANGE_VIRTUAL_MACHINE_STATE_SUCCESS";
export const CHANGE_VIRTUAL_MACHINE_STATE_FAIL = "CHANGE_VIRTUAL_MACHINE_STATE_FAIL";

/* VIRTUAL MACHINE DETAIL */
export const GET_VIRTUAL_MACHINE_DETAIL_REQUEST = "GET_VIRTUAL_MACHINE_DETAIL_REQUEST"
export const GET_VIRTUAL_MACHINE_DETAIL_SUCCESS = "GET_VIRTUAL_MACHINE_DETAIL_SUCCESS"
export const GET_VIRTUAL_MACHINE_DETAIL_FAIL = "GET_VIRTUAL_MACHINE_DETAIL_FAIL"

export const RESET_VIRTUAL_MACHINE_FLAGS = "RESET_VIRTUAL_MACHINE_FLAGS";

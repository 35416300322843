/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AlertNotifyEventType {
    INCIDENT_OPENED = 'INCIDENT_OPENED',
    INCIDENT_REMINDER_UNACKNOWLEDGED = 'INCIDENT_REMINDER_UNACKNOWLEDGED',
    INCIDENT_ACKNOWLEDGED = 'INCIDENT_ACKNOWLEDGED',
    INCIDENT_CLOSED = 'INCIDENT_CLOSED',
}

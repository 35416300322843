import {
    GET_SSHKEYS,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    ADD_SSH_KEY,
    ADD_SSH_KEY_SUCCESS,
    ADD_SSH_KEY_FAIL,
    IMPORT_SSH_KEYS,
    IMPORT_SSH_KEYS_SUCCESS,
    IMPORT_SSH_KEYS_FAIL,
    DELETE_SSH_KEY,
    DELETE_SSH_KEY_SUCCESS,
    DELETE_SSH_KEY_FAIL,
} from "./actionTypes"

// common success
export const KeyApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const KeyApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getSSHKeys = keys => ({
    type: GET_SSHKEYS,
    payload: keys,
})

export const addSSHKey = sshkey => ({
    type: ADD_SSH_KEY,
    payload: sshkey,
});

export const addSSHKeySuccess = sshkey => ({
    type: ADD_SSH_KEY_SUCCESS,
    payload: sshkey,
});

export const addSSHKeyFail = error => ({
    type: ADD_SSH_KEY_FAIL,
    payload: error.response.data,
});

export const importSSHKeys = gh_username => ({
    type: IMPORT_SSH_KEYS,
    payload: gh_username,
});

export const importSSHKeysSuccess = sshkey => ({
    type: IMPORT_SSH_KEYS_SUCCESS,
    payload: sshkey,
});

export const importSSHKeysFail = error => ({
    type: IMPORT_SSH_KEYS_FAIL,
    payload: error.response.data,
});

export const deleteSSHKey = sshkey => ({
    type: DELETE_SSH_KEY,
    payload: sshkey,
});

export const deleteSSHKeySuccess = sshkey => ({
    type: DELETE_SSH_KEY_SUCCESS,
    payload: sshkey,
});

export const deleteSSHKeyFail = error => ({
    type: DELETE_SSH_KEY_FAIL,
    payload: error,
});

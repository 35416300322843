import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//  Redux States
import { 
    GET_VIRTUAL_MACHINES_REQUEST,
    ADD_VIRTUAL_MACHINE,
    CHANGE_VIRTUAL_MACHINE_STATE_REQUEST,
    GET_VIRTUAL_MACHINE_DETAIL_REQUEST
} from "./actionTypes"
import {
    getVirtualMachinesSuccess,
    getVirtualMachinesFail,
    addVirtualMachineSuccess,
    addVirtualMachineFail,
    changeVirtualMachineStateSuccess,
    changeVirtualMachineStateFail,
    getVirtualMachineDetailSuccess,
    getVirtualMachineDetailFail,
} from "./actions"

//Include Both Helper File with needed methods
import { 
    getVirtualMachines,
    addVirtualMachine,
    changeVirtualMachineState,
    getVirtualMachineDetail
} from "helpers/galliumbackend_helper"

function* onGetVirtualMachines() {
    try {
        const response = yield call(getVirtualMachines)
        yield put(getVirtualMachinesSuccess(GET_VIRTUAL_MACHINES_REQUEST, response))
    } catch (error) {
        yield put(getVirtualMachinesFail(GET_VIRTUAL_MACHINES_REQUEST, error))
    }
}

function* onAddNewVirtualMachine({ data, hypervisor, history }) {
    try {
        const response = yield call(addVirtualMachine, data, hypervisor);
        yield put(addVirtualMachineSuccess(response));
        toast.success("VM Added Successfully", { autoClose: 3000 });
        history(`/vm/` + response.command.vmSlug);
    } catch (error) {
        yield put(addVirtualMachineFail(error));
        toast.error("VM Added Failed", { autoClose: 3000 });
    }
}

function* onChangeVirtualMachineState({ vm, payload }) {
    try {
        const response = yield call(changeVirtualMachineState, vm, payload);
        yield put(changeVirtualMachineStateSuccess(response));
        toast.info("VM State Change Requested", { autoClose: 3000 });
    } catch (error) {
        yield put(changeVirtualMachineStateFail(error));
        toast.error("VM State Change Request Failed", { autoClose: 3000 });
    }
}

function* onGetVirtualMachineDetail({ virtualMachineId }) {
    try {
        const response = yield call(getVirtualMachineDetail, virtualMachineId)
        yield put(getVirtualMachineDetailSuccess(response))
    } catch (error) {
        yield put(getVirtualMachineDetailFail(error))
    }
}

export function* watchGetVirtualMachines() {
    yield takeEvery(GET_VIRTUAL_MACHINES_REQUEST, onGetVirtualMachines);
}

export function* watchAddNewVM() {
    yield takeEvery(ADD_VIRTUAL_MACHINE, onAddNewVirtualMachine);
}

export function* watchChangeVirtualMachineState() {
    yield takeEvery(CHANGE_VIRTUAL_MACHINE_STATE_REQUEST, onChangeVirtualMachineState);
}

export function* watchGetVirtualMachineDetail() {
    yield takeEvery(GET_VIRTUAL_MACHINE_DETAIL_REQUEST, onGetVirtualMachineDetail);
}

function* VirtualMachinesSaga() {
    yield all([
        fork(watchGetVirtualMachines),
        fork(watchAddNewVM),
        fork(watchChangeVirtualMachineState),
        fork(watchGetVirtualMachineDetail),
    ]
    );
}

export default VirtualMachinesSaga

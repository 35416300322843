/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum OrgUserRole {
    OWNER = 'OWNER',
    USER = 'USER',
    PENDING_INVITE = 'PENDING_INVITE',
    EXPIRED_INVITE = 'EXPIRED_INVITE',
}

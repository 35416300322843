import { all, fork } from "redux-saga/effects"

//layout
import LayoutSaga from "./layouts/saga";

//public
import AuthSaga from "./auth/login/saga"
import HypervisorsSaga from "./hypervisors/saga";
import VmViewerSaga from "./vnc/saga";
import SSHKeysSaga from "./inventory/saga";
import VirtualMachinesSaga from "./vm/saga";
import TemplatesSaga from "./templates/saga";

import accountSaga from "./auth/register/saga";


export default function* rootSaga() {
    yield all([
    //public
        fork(LayoutSaga),
        fork(AuthSaga),
        fork(HypervisorsSaga),
        fork(VmViewerSaga),
        fork(SSHKeysSaga),
        fork(VirtualMachinesSaga),
        fork(TemplatesSaga),
        fork(accountSaga)
    ])
}

import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import BreadCrumb from '../../../Components/Common/Breadcrumb';

import TableContainer from '../../../Components/Common/TableContainer';

import {
    Col,
    Container,
} from "reactstrap"

import Loader from 'Components/Gallium/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { useListPersonalisation } from 'GalliumAPIHooks/Marketing/MarketingHooks';
import ShowAllKeysModal from './ViewAllModal';
import URLComponent from './URLComponent';

const PersonalisationsListTable = ({passkey}) => {
    const { data, error, isLoading } = useListPersonalisation(passkey)

    const columns = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "userName",
                filterable: false,
                Cell: (cellProps) => {
                    return <ShowAllKeysModal {...cellProps} />
                },
            },
            {
                Header: "Email",
                accessor: "userEmail",
                filterable: false,
                Cell: (cellProps) => {
                    return (
                        <React.Fragment>
                            {cellProps.value}
                        </React.Fragment>
                    );
                },
            },
            {
                Header: "URLs",
                accessor: "slug",
                filterable: false,
                Cell: (cellProps) => {
                    return <URLComponent {...cellProps} />
                },
            }
        ],[]
    );

    return (
        <React.Fragment>
            {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                <div className="row">
                    <Col lg={12}>
                        <div className="card" id="tasksList">
                            <div className="card-header border-0">
                                <div className="d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">Website Personalisations</h5>
                                    <div className="flex-shrink-0">
                                        <div className="d-flex flex-wrap gap-2">
                                            <Link to="/landingpage/add">
                                                <button className="btn btn-soft-primary add-btn me-1"><i className="mdi mdi-plus-circle-outline me-1"></i> New Landing Page</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body pt-0">
                                <TableContainer
                                    columns={columns}
                                    data={(data || [])}
                                    isGlobalFilter={true}
                                    isAddUserList={false}
                                    customPageSize={20}
                                    className="custom-header-css"
                                    divClass="table-responsive table-card mb-3"
                                    tableClass="align-middle table-nowrap mb-0"
                                    theadClass="table-light table-nowrap"
                                    thClass="table-light text-muted"
                                    SearchPlaceholder='Search for a landing page...'
                                />
                            </div>
                        </div>
                    </Col>
                </div>
            )}            
        </React.Fragment>
    );
};

export default PersonalisationsListTable;
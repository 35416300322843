import {
    GET_VNC_URL,
    GET_VNC_URL_FAIL,
    GET_VNC_URL_SUCCESS,
    RESET_VNC_URL_FLAGS
} from "./actionTypes"

export const getVNCUrl = (virtualMachineSlug: string) => ({
    type: GET_VNC_URL,
    virtualMachineSlug
})

export const getVNCUrlSuccess = vnc_url => ({
    type: GET_VNC_URL_SUCCESS,
    payload: vnc_url,
})

export const getVNCUrlFail = error => ({
    type: GET_VNC_URL_FAIL,
    payload: error,
})

export const resetVNCUrlFlags = () => ({
    type: RESET_VNC_URL_FLAGS
})
import React from 'react';
import { Alert } from 'reactstrap';

import { GalliumApiErrorResponse } from 'generated';

type ErrorAlertProps = {
  errorObj: GalliumApiErrorResponse;
};

const ErrorAlert: React.FC<ErrorAlertProps> = ({ errorObj }) => {
    
    if (errorObj && errorObj.error && errorObj.errorId) {
        return (
            <Alert color="danger">
                <strong>{errorObj.error}</strong> <br />
                Event ID: {errorObj.errorId}
            </Alert>
        )
    } else {
        return null
    }
};

export default ErrorAlert;
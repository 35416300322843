import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Import Icons
import FeatherIcon from "feather-icons-react";

const Navdata = () => {
    const history = useNavigate();
    //state data
    const [isVirtualMachines, setIsVirtualMachines] = useState(false);

    const [isHypervisors, setIsHypervisors] = useState(false);
    const [isPersonalisation, setIsPersonalisation] = useState(false);
    const [isInventory, setIsInventory] = useState(false);
    const [isSettings, setIsSettings] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Hypervisors');

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            const activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                const id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Hypervisors') {
            setIsHypervisors(false);
        }
    }, [
        history,
        iscurrentState,
        isHypervisors
    ]);


    const menuItems = [

        {
            label: "Marketing Toolkit",
            isHeader: true,
        },
        {
            id: "servicegateway",
            label: "Personalisation",
            icon: <FeatherIcon icon="target" className="icon-dual" />,
            link: "/landingpage",
            stateVariables: isPersonalisation,
            click: function (e) {
                setIsPersonalisation(!isPersonalisation);
                setIscurrentState('ServiceGateway');
                updateIconSidebar(e);
            }
        }
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
/* HYPERVISORS */

export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_HYPERVISORS = "GET_HYPERVISORS"

/* HYPERVISOR DETAIL */
export const GET_HYPERVISOR_DETAIL_REQUEST = "GET_HYPERVISOR_DETAIL_REQUEST"
export const GET_HYPERVISOR_DETAIL_SUCCESS = "GET_HYPERVISOR_DETAIL_SUCCESS"
export const GET_HYPERVISOR_DETAIL_FAIL = "GET_HYPERVISOR_DETAIL_FAIL"

export const GET_HYPERVISOR_METRICS = "GET_HYPERVISOR_METRICS"
export const GET_HYPERVISOR_METRICS_SUCCESS = "GET_HYPERVISOR_METRICS_SUCCESS"
export const GET_HYPERVISOR_METRICS_FAIL = "GET_HYPERVISOR_METRICS_FAIL"

export const UPDATE_HYPERVISOR = "UPDATE_HYPERVISOR"
export const UPDATE_HYPERVISOR_SUCCESS = "UPDATE_HYPERVISOR_SUCCESS"
export const UPDATE_HYPERVISOR_FAIL = "UPDATE_HYPERVISOR_FAIL"

/* HYPERVISOR STORAGE */

export const GET_HYPERVISOR_STORAGE = "GET_HYPERVISOR_STORAGE"
export const GET_HYPERVISOR_STORAGE_SUCCESS = "GET_HYPERVISOR_STORAGE_SUCCESS"
export const GET_HYPERVISOR_STORAGE_FAIL = "GET_HYPERVISOR_STORAGE_FAIL"

export const UPDATE_HYPERVISOR_STORAGE = "UPDATE_HYPERVISOR_STORAGE"
export const UPDATE_HYPERVISOR_STORAGE_SUCCESS = "UPDATE_HYPERVISOR_STORAGE_SUCCESS"
export const UPDATE_HYPERVISOR_STORAGE_FAIL = "UPDATE_HYPERVISOR_STORAGE_FAIL"

export const DESTROY_HYPERVISOR_STORAGE_REQUEST = "DESTROY_HYPERVISOR_STORAGE_REQUEST"
export const DESTROY_HYPERVISOR_STORAGE_SUCCESS = "DESTROY_HYPERVISOR_STORAGE_SUCCESS"
export const DESTROY_HYPERVISOR_STORAGE_FAIL = "DESTROY_HYPERVISOR_STORAGE_FAIL"


/**
* Add HYPERVISOR
*/
export const ADD_HYPERVISOR = "ADD_NEW_HYPERVISOR";
export const ADD_HYPERVISOR_SUCCESS = "ADD_HYPERVISOR_SUCCESS";
export const ADD_HYPERVISOR_FAIL = "ADD_HYPERVISOR_FAIL";

export const RESET_HYPERVISOR_FLAGS = "RESET_HYPERVISOR_FLAGS";